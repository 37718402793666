import {takeLatest, takeEvery, put, all, call} from 'redux-saga/effects'

import UserActionTypes from "./user.types";

import {auth, googleProvider, createUserProfileDocument, getCurrentUser} from "../../firebase/firebase.utils";

import {
    signInSuccess,
    signInFailure,
    signOutSuccess,
    signOutFailure,
    signUPFailure,
    signUpSuccess
} from "./user.actions";

export function* getSnapshotFromUserAuth(userAuth, additionalData) {
    try{
        const userRef= yield call(createUserProfileDocument, userAuth, additionalData)
        const userSnapshot= yield userRef.get()
        yield put(signInSuccess({id: userSnapshot.id, ...userSnapshot.data()}))
    } catch(error){
        yield put(signInFailure(error))
    }
}

export function* signInWithGoogle(){
    try{
        const user= yield auth.signInWithPopup(googleProvider)
        console.log('user: '+user)
        yield getSnapshotFromUserAuth(user)
    } catch(err){
        yield put(signInFailure(err))
    }
}

export function* signInWithEmail({payload: {email, password}}){
    try{
        const {user} = yield auth.signInWithEmailAndPassword(email, password)
        yield getSnapshotFromUserAuth(user)
    } catch(error){
       yield put(signInFailure(error))
    }
}

export function* onGoogleSignInStart(){
    yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle)
}

export function* onEmailSignInStart(){
    yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail)
}

export function* isUserAuthenticated(){
    try{
        const userAuth = yield getCurrentUser()
        if (!userAuth) return
        yield getSnapshotFromUserAuth(userAuth)
    } catch(err){
        yield put(signInFailure(err))
    }
}

export function* onCheckUserSession(){
    yield takeEvery(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated)
}

export function* signOut(){
    try{
        yield auth.signOut()
        yield put(signOutSuccess())
    } catch(err){
        yield put(signOutFailure(err))
    }
}

export function* onSignOutStart() {
    yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut)
}

export function* signUp({payload:{email, password, displayName}}){
    try{
        const {user} = yield auth.createUserWithEmailAndPassword(email, password)
        yield put(signUpSuccess({user, additionalData:{displayName} }))
    } catch(err){
        yield put(signUPFailure(err))
    }
}

export function* onSignUpStart(){
    yield takeLatest(UserActionTypes.SIGN_UP_START, signUp)
}

export function* onSignUpSuccess(){
    yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp)
}

export function* signInAfterSignUp({payload: {user, additionalData}}){
    yield getSnapshotFromUserAuth(user, additionalData)
}



export function* userSagas(){
    yield all([
        call(onGoogleSignInStart),
        call(onEmailSignInStart),
        call(onCheckUserSession),
        call(onSignOutStart),
        call(onSignUpStart),
        call(onSignUpSuccess)
    ])
}
